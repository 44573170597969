import React from "react"
import { Link } from "gatsby"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import "./menu.scss"
import "../../pages/index.scss"

import ImgMenu1 from "../../images/menu1.jpg"
import ImgMenu2 from "../../images/menu2.jpg"
import ImgMenu4 from "../../images/menu4.jpg"
import ImgMenu5 from "../../images/menu5.jpg"

const Menu = () => (

	<div className="tz-menu">
		<div className="container">
			<Row className="tz-kurzy">
				<Col sm="3">
					<Link className="tz-kurzy__item" to="/tanecni-pro-dospele" data-sal="fade" data-sal-duration="1000">
						<img className="tz-kurzy__image" alt="Taneční kurzy pro dospělé" src={ImgMenu1} />
						<h2 className="tz-kurzy__name">Taneční kurzy pro&nbsp;dospělé</h2>
						<div className="tz-button tz-button--small tz-kurzy__button">detail</div>
						<div className="tz-kurzy__bg"></div>
						<div className="tz-kurzy__icon">
							<svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"></path></svg>
						</div>
					</Link>
				</Col>
				<Col sm="3">
					<Link className="tz-kurzy__item" to="/latin-fever-for-ladies" data-sal="fade" data-sal-duration="1000">
						<img className="tz-kurzy__image" alt="Latin Fever" src={ImgMenu2} />
						<h2 className="tz-kurzy__name">Latin Fever for&nbsp;ladies</h2>
						<div className="tz-button tz-button--small tz-kurzy__button">detail</div>
						<div className="tz-kurzy__bg"></div>
						<div className="tz-kurzy__icon">
							<svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"></path></svg>
						</div>
					</Link>
				</Col>
				<Col sm="3">
					<Link className="tz-kurzy__item" to="/tanecni-pripravka-pro-deti" data-sal="fade" data-sal-duration="1000">
						<img className="tz-kurzy__image" alt="Taneční přípravka pro děti" src={ImgMenu5} />
						<h2 className="tz-kurzy__name">Taneční přípravka pro&nbsp;děti <span className="nowrap">5-6&nbsp;let</span></h2>
						<div className="tz-button tz-button--small tz-kurzy__button">detail</div>
						<div className="tz-kurzy__bg"></div>
						<div className="tz-kurzy__icon">
							<svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"></path></svg>
						</div>
					</Link>
				</Col>
				<Col sm="3">
					<Link className="tz-kurzy__item" to="/tanecni-kurz-pro-deti" data-sal="fade" data-sal-duration="1000">
						<img className="tz-kurzy__image" alt="Taneční kurz pro děti" src={ImgMenu4} />
						<h2 className="tz-kurzy__name">Tanec pro&nbsp;děti <span className="nowrap">7-12&nbsp;let</span></h2>
						<div className="tz-button tz-button--small tz-kurzy__button">detail</div>
						<div className="tz-kurzy__bg"></div>
						<div className="tz-kurzy__icon">
							<svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"></path></svg>
						</div>
					</Link>
				</Col>
			</Row>
		</div>
	</div>

)

export default Menu